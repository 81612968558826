import React from 'react';
import "./index.scss";
import Spinner from '../../components/Spinner';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment/moment';
import DateRangeCalendar from '../../components/DateRangeCalendar';
import LogViewer from '../../components/LogViewer';

export default function Post(props) {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [message, setMessage] = React.useState(null);
    const [dropdown, setDropdown] = React.useState(false);
    const [country, setCountry] = React.useState({ name: 'Bosna', value: 'BA' });
    const [searchValue, setSearchValue] = React.useState(null);
    const [selectedStatus, setSelectedStatus] = React.useState(5);
    const [selectMode, setSelectMode] = React.useState(false);
    const [selectedOrders, setSelectedOrders] = React.useState([]);
    const [printError, setPrintError] = React.useState(null);
    const [printSpinner, setPrintSpinner] = React.useState(false);
    const [pickupSpinner, setPickupSpinner] = React.useState(false);
    const [pickupError, setPickupError] = React.useState(null);

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);
    const [dateRange, setDateRange] = React.useState({ start: sevenDaysAgo, end: today, firstTime: true });

    const searchRef = React.useRef(null);

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getAllOrders`,
            data: {
                filters: {
                    Country: country.value,
                    Status: selectedStatus,
                    Name: searchValue,
                    startDate: dateRange.start !== null ? dateRange.start : null,
                    endDate: dateRange.end !== null ? dateRange.end : null
                }
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const oneExpressUpdate = (setLoading, setError) => {
        setLoading(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/oneExpressUpdateOrderStatuses`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
            }
            else {
                setError(res.data.data);
            }
        }).catch(() => {
            setError('CRITICAL_ONEEXPRESS_ERROR');
        }).finally(() => {
            setLoading(false);
        });
    }

    const bexUpdate = (setLoading, setError) => {
        setLoading(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/bexUpdateOrderStatuses`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
            }
            else {
                setError(res.data.data);
            }
        }).catch(() => {
            setError('CRITICAL_BEX_ERROR');
        }).finally(() => {
            setLoading(false);
        });
    }

    const selectOrder = (shipmentID, country, rocketID) => {
        if (country === 'BA') {
            setSelectedOrders(prev => {
                if (prev.includes(shipmentID)) {
                    return prev.filter(id => id !== shipmentID);
                } else {
                    return [...prev, shipmentID];
                }
            });
        } else if (country === 'RS') {
            setSelectedOrders(prev => {
                if (prev.includes(rocketID)) {
                    return prev.filter(id => id !== rocketID);
                } else {
                    return [...prev, rocketID];
                }
            });
        }
    }

    const printMultipleLabels = async (country, shipments) => {
        setPrintSpinner(true);
        if (country === "BA") {
            try {
                const response = await axios.post(
                    `${backendModule.backendURL}/orders/oneExpressPrintMultipleLabels`,
                    { Shipments: shipments },
                    {
                        withCredentials: true,
                        responseType: 'arraybuffer',
                        ...backendModule.axiosConfig
                    }
                );

                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `Naljepnice`;
                link.click();
            } catch (error) {
                setPrintError("Greška prilikom pripreme naljepnica...");
            } finally {
                setPrintSpinner(false);
                getData();
            }
        }
    };

    const requestOneExpressPickup = async () => {
        setPickupSpinner(true);
        try {
            const response = await axios.post(
                `${backendModule.backendURL}/orders/oneExpressRequestPickup`,
                {},
                {
                    withCredentials: true,
                    responseType: 'arraybuffer',
                    ...backendModule.axiosConfig
                }
            );

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `Lista preuzimanja`;
            link.click();
        } catch (error) {
            setPickupError("Greška prilikom naručivanja...");
        } finally {
            setPickupSpinner(false);
            props.refresh();
        }
    }

    const sendToBex = () => {
        setPickupSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/bexAddOrders`,
            data: {
                Shipments: selectedOrders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
            }
            else {
                setPickupError('Došlo je do greške');
            }
        }).catch(() => {
            setPickupError('CRITICAL_BEX_ERROR');
        }).finally(() => {
            setPickupSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, [country, searchValue, selectedStatus]);

    React.useEffect(() => {
        if (dateRange.start !== null && dateRange.end !== null && !dateRange.firstTime) {
            getData();
        }
    }, [dateRange]);

    React.useEffect(() => {
        const socket = new WebSocket(backendModule.webSocketURL);

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'oneexpress_pickup') {
                setPickupError(data.message);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket greška:', error);
        };

        return () => {
            socket.close();
        };
    }, []);

    return (
        <div className='route__post'>
            <div className='route__post__header'>
                <div className='route__post__header__dropdown'>
                    <div onClick={() => setDropdown(d => !d)} className={`route__post__header__dropdown__head ${dropdown ? 'route__post__header__dropdown__head--active' : ''}`}>
                        <p>{country.name}</p>
                    </div>
                    <div className={`route__post__header__dropdown__body ${dropdown ? 'route__post__header__dropdown__body--active' : ''}`}>
                        <p onClick={() => { setCountry({ name: 'Bosna', value: 'BA' }); setDropdown(false) }}>Bosna</p>
                        <p onClick={() => { setCountry({ name: 'Srbija', value: 'RS' }); setDropdown(false) }}>Srbija</p>
                        <p onClick={() => { setCountry({ name: 'Crna Gora', value: 'ME' }); setDropdown(false) }}>Crna Gora</p>
                        <p onClick={() => { setCountry({ name: 'Hrvatska', value: 'HR' }); setDropdown(false) }}>Hrvatska</p>
                        <p onClick={() => { setCountry({ name: 'Makedonija', value: 'MK' }); setDropdown(false) }}>Makedonija</p>
                        <p onClick={() => { setCountry({ name: 'Kosovo', value: 'XK' }); setDropdown(false) }}>Kosovo</p>
                        <p onClick={() => { setCountry({ name: 'Albanija', value: 'AL' }); setDropdown(false) }}>Albanija</p>
                        <p onClick={() => { setCountry({ name: 'Mađarska', value: 'HU' }); setDropdown(false) }}>Mađarska</p>
                        <p onClick={() => { setCountry({ name: 'Bugarska', value: 'BG' }); setDropdown(false) }}>Bugarska</p>
                    </div>
                </div>
                <div onClick={() => setSearchValue(searchRef.current.value)} className='route__post__header__search'>
                    <input ref={searchRef} type='text' placeholder='Pretraži po imenu...' />
                    <img src='images/header__searchW.svg' alt='' />
                </div>
                <DateRangeCalendar range={dateRange} setRange={setDateRange} />
                <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <LogViewer mainFunction={oneExpressUpdate} />)} className='route__post__header__button'>
                    <p>Ažuriraj BA</p>
                </div>
                <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <LogViewer mainFunction={bexUpdate} />)} className='route__post__header__button'>
                    <p>Ažuriraj RS</p>
                </div>
                <div onClick={(e) => animateBox(e, <SettingsModal />)} className='route__post__header__button'>
                    <p>Postavke</p>
                </div>
            </div>

            {!spinner && !message ? <div className='route__post__body'>
                <div className='route__post__body__kpis'>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>Za print</p>
                            <p>{data?.data?.statusCounts?.status5}</p>
                        </span>
                        <img src='images/zaprint.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>Na čekanju</p>
                            <p>{data?.data?.statusCounts?.status0}</p>
                        </span>
                        <img src='images/order.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>U isporuci</p>
                            <p>{data?.data?.statusCounts?.status1}</p>
                        </span>
                        <img src='images/uisporuci.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>Isporučenih</p>
                            <p>{data?.data?.statusCounts?.status3}</p>
                        </span>
                        <img src='images/isporucenih.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>Vraćenih</p>
                            <p>{data?.data?.statusCounts?.status2}</p>
                        </span>
                        <img src='images/vracenih.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            {window.innerWidth > 1310 ? <p>Procenat povrata</p> : <p>P. povrata</p>}
                            <p>{data?.data?.returnPercentage} %</p>
                        </span>
                        <img src='images/povrat.png' alt='' />
                    </div>
                </div>

                <div className='route__post__body__statusFilter'>
                    <div onClick={() => setSelectedStatus(5)} className='route__post__body__statusFilter__filter'>
                        <p>Za print</p>
                        <div style={{ background: selectedStatus === 5 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(0)} className='route__post__body__statusFilter__filter'>
                        <p>Na čekanju</p>
                        <div style={{ background: selectedStatus === 0 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(1)} className='route__post__body__statusFilter__filter'>
                        <p>U isporuci</p>
                        <div style={{ background: selectedStatus === 1 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(3)} className='route__post__body__statusFilter__filter'>
                        <p>Isporučeni</p>
                        <div style={{ background: selectedStatus === 3 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(2)} className='route__post__body__statusFilter__filter'>
                        <p>Vraćeni</p>
                        <div style={{ background: selectedStatus === 2 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(4)} className='route__post__body__statusFilter__filter'>
                        <p>Poništeni</p>
                        <div style={{ background: selectedStatus === 4 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                </div>

                <div className='route__post__body__table'>
                    {
                        data?.data?.orders?.map(item => {
                            return <div
                                onClick={(e) => { !selectMode ? animateBox({ currentTarget: e.currentTarget.parentNode.parentNode }, <OrderDetails isAdmin={props.isAdmin} data={item} orderID={item.ID} refresh={getData} />) : selectOrder(item.ShipmentID, item.Country, item.ID) }}
                                className={selectedOrders?.includes(item.ShipmentID) || selectedOrders?.includes(item.ID) ? 'route__post__body__table__row route__post__body__table__row__selected' : 'route__post__body__table__row'}
                            >
                                <p>{item.ID}</p>
                                <p>{item.Name}</p>
                                <p>{item.Product}</p>
                                {window.innerWidth > 492 && <p>{item.Price}</p>}
                                <p>{moment(item.createdAt).format('DD.MM.YYYY.')}</p>
                            </div>
                        })
                    }
                </div>

                {country.value === 'BA' && <div className='route__post__body__print'>
                    <div onClick={() => { setSelectMode(sm => !sm); setSelectedOrders([]) }} className='route__post__body__print__button'>
                        {!selectMode && <p>Select mode</p>}
                        {selectMode && <p>Ugasi select mode</p>}
                    </div>
                    {selectedOrders.length > 0 && <div onClick={() => printMultipleLabels(country.value, selectedOrders)} className='route__post__body__print__button'>
                        {!printSpinner && <p>Isprintaj selektovano</p>}
                        {printSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>}
                    <div onClick={() => requestOneExpressPickup()} className='route__post__body__print__button'>
                        {!pickupSpinner && <p>Naruči preuzimanje</p>}
                        {pickupSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>
                    {printError && <p style={{ color: 'orange' }}>{printError}</p>}
                    {pickupError && <p style={{ color: 'orange' }}>{pickupError}</p>}
                </div>}
                {country.value === 'RS' && <div className='route__post__body__print'>
                    <div onClick={() => { setSelectMode(sm => !sm); setSelectedOrders([]) }} className='route__post__body__print__button'>
                        {!selectMode && <p>Select mode</p>}
                        {selectMode && <p>Ugasi select mode</p>}
                    </div>
                    {selectedOrders.length > 0 && <div onClick={() => sendToBex()} className='route__post__body__print__button'>
                        {!pickupSpinner && <p>Pošalji</p>}
                        {pickupSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>}
                    {printError && <p style={{ color: 'orange' }}>{printError}</p>}
                    {pickupError && <p style={{ color: 'orange' }}>{pickupError}</p>}
                </div>}
            </div> : null}
            {spinner && <Spinner color='#ffffff' style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {message && <p style={{ color: 'white' }}>{message}</p>}
        </div>
    )
}


const OrderDetails = (props) => {
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [printSpinner, setPrintSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const cancelShipment = (ID, country) => {
        setBtnSpinner(true);
        if (country == 'BA') {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/orders/oneExpressCancelShipment`,
                data: {
                    ID: ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.refresh();
                    props.onClose();
                }
                else {
                    setMessage(res.data.data);
                }
            }).catch(() => {
                setMessage('Server ne reagira...');
            }).finally(() => {
                setBtnSpinner(false);
            });
        } else if (country == 'RS') {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/orders/bexCancelShipment`,
                data: {
                    ID: ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.refresh();
                    props.onClose();
                }
                else {
                    setMessage(res.data.data);
                }
            }).catch(() => {
                setMessage('Server ne reagira...');
            }).finally(() => {
                setBtnSpinner(false);
            });
        } else {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/orders/changeStatus`,
                data: {
                    token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                    ID: ID,
                    Status: 4
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.refresh();
                    props.onClose();
                }
                else {
                    setMessage('Greška prilikom dohvatanja podataka...');
                }
            }).catch(() => {
                setMessage('Server ne reagira...');
            }).finally(() => {
                setBtnSpinner(false);
                props.onClose();
            });
        }
    }

    const printLabel = async (country, shipmentId, internalID) => {
        setPrintSpinner(true);
        if (country === "BA") {
            try {
                const response = await axios.post(
                    `${backendModule.backendURL}/orders/oneExpressPrintSingleLabel`,
                    { ShipmentID: shipmentId, InternalID: internalID },
                    {
                        withCredentials: true,
                        responseType: 'arraybuffer',
                        ...backendModule.axiosConfig
                    }
                );

                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `Naljepnica_shipmentID_${shipmentId}`;
                link.click();
            } catch (error) {
                setMessage("Greška prilikom pripreme naljepnice...");
            } finally {
                props.refresh();
                setPrintSpinner(false);
            }
        } else if (country === 'RS') {
            try {
                const response = await axios.post(
                    `${backendModule.backendURL}/orders/bexPrintSingleLabel`,
                    { ShipmentID: shipmentId, InternalID: internalID },
                    {
                        withCredentials: true,
                        responseType: 'arraybuffer',
                        ...backendModule.axiosConfig
                    }
                );

                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `Naljepnica_shipmentID_${shipmentId}`;
                link.click();
            } catch (error) {
                setMessage("Greška prilikom pripreme naljepnice...");
            } finally {
                props.refresh();
                setPrintSpinner(false);
            }
        }
    };


    return <div className='component__detailsModal'>
        <div className='component__detailsModal__wrapper'>
            <div className='component__detailsModal__wrapper__header'>
                <h1 className='component__detailsModal__wrapper__header__h1'>Detalji paketa ID - {props.orderID}</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>

            {!message ? <div className='component__detailsModal__wrapper__body'>
                <div className='component__detailsModal__wrapper__body__block'>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Ime kupca</label>
                        <p>{props.data?.Name} {props.data?.Surname}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Broj telefona</label>
                        <p>{props.data?.Phone}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Grad</label>
                        <p>{props.data?.City}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Poštanski broj</label>
                        <p>{props.data?.ZIP}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Ulica</label>
                        <p>{props.data?.Street}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Količina</label>
                        <p>{props.data?.Quantity}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Cijena</label>
                        <p>{props.data?.Price}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Proizvod</label>
                        <p>{props.data?.Product}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Država</label>
                        <p>{props.data?.Country}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Napomena</label>
                        <p>{props.data?.Note}</p>
                    </div>
                </div>
                <div className='component__detailsModal__wrapper__body__block'>
                    <p>Lead ID: {props.data?.LeadID}</p>
                    <p>Tip: {props.data?.Type}</p>
                    <p>Partner ID: {props.data?.PartnerID}</p>
                    <p>Shipment ID: {props.data?.ShipmentID}</p>
                </div>
                {props.data?.Country !== 'HR' && <div className='component__detailsModal__wrapper__body__actions'>
                    {<div onClick={() => cancelShipment(props.data?.ID, props.data?.Country)} style={{ background: 'rgb(159 27 27)', color: '#ffffff', border: 'none' }} className='component__detailsModal__wrapper__body__actions__button'>
                        {!btnSpinner && <p>Poništi slanje</p>}
                        {btnSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>}
                    <div onClick={() => printLabel(props.data?.Country, props.data?.ShipmentID, props.orderID)} className='component__detailsModal__wrapper__body__actions__button'>
                        {!printSpinner && <p>Isprintaj naljepnicu</p>}
                        {printSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>
                </div>}
                {props.data?.Country === 'HR' && <div className='component__detailsModal__wrapper__body__actions'>
                    {<div onClick={() => cancelShipment(props.data?.ID, props.data?.Country)} style={{ background: 'rgb(159 27 27)', color: '#ffffff', border: 'none' }} className='component__detailsModal__wrapper__body__actions__button'>
                        {!btnSpinner && <p>Poništi slanje</p>}
                        {btnSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>}
                    <div onClick={(e) => animateBox(e, <ManualChangeStatus refresh={props.refresh} orderID={props.data?.ID} />)} className='component__detailsModal__wrapper__body__actions__button'>
                        {!printSpinner && <p>Promijeni status</p>}
                        {printSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>
                </div>}
            </div> : null}
            {message && <p style={{ color: 'orange' }}>{message}</p>}
        </div>
    </div>
}

const ManualChangeStatus = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const changeStatus = (status) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/changeStatus`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                ID: props.orderID,
                Status: status
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                setMessage('Uspješno promijenjeno');
            }else{
                setMessage('Server ne reagira...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__manualChangeStatus'>
        <div className='component__manualChangeStatus__wrapper'>
            <img onClick={props.onClose} src='images/closemenu.svg' alt='' />

            {!spinner && <div className='component__manualChangeStatus__wrapper__list'>
                <p onClick={() => changeStatus(5)}>Za print</p>
                <p onClick={() => changeStatus(0)}>Na čekanju</p>
                <p onClick={() => changeStatus(1)}>U isporuci</p>
                <p onClick={() => changeStatus(3)}>Isporučeno</p>
                <p onClick={() => changeStatus(2)}>Vraćeno</p>
            </div>}
            {spinner && <Spinner style={{width: '30px', height: '30px', display: 'block', marginLeft: 'auto', marginRight: 'auto', color: 'white'}} color='white' />}
            <br></br>
            {message && <p style={{color: 'orange'}}>{message}</p>}
        </div>
    </div>
}

const SettingsModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [data, setData] = React.useState([]);

    const BAapiRef = React.useRef(null);
    const BAnameRef = React.useRef(null);
    const BAzipRef = React.useRef(null);
    const BAstreetRef = React.useRef(null);
    const BAcityRef = React.useRef(null);
    const RSapiRef = React.useRef(null);
    const RSnameRef = React.useRef(null);
    const RSzipRef = React.useRef(null);
    const RSstreetRef = React.useRef(null);
    const RScityRef = React.useRef(null);

    const saveAccountBA = () => {
        setBtnSpinner(true);

        axios({
            method: "POST",
            url: data[0] ? `${backendModule.backendURL}/postAccounts/editAccount` : `${backendModule.backendURL}/postAccounts/addAccount`,
            data: {
                ApiKey: BAapiRef.current.value,
                SenderName: BAnameRef.current.value,
                SenderPostalCode: BAzipRef.current.value,
                SenderStreet: BAstreetRef.current.value,
                SenderCity: BAcityRef.current.value,
                Country: 'BA',
                ID: data[0] ? data[0].ID : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
            props.onClose();
        });
    }

    const saveAccountRS = () => {
        setBtnSpinner(true);

        axios({
            method: "POST",
            url: data[1] ? `${backendModule.backendURL}/postAccounts/editAccount` : `${backendModule.backendURL}/postAccounts/addAccount`,
            data: {
                ApiKey: RSapiRef.current.value,
                SenderName: RSnameRef.current.value,
                SenderPostalCode: RSzipRef.current.value,
                SenderStreet: RSstreetRef.current.value,
                SenderCity: RScityRef.current.value,
                Country: 'RS',
                ID: data[1] ? data[1].ID : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
            props.onClose();
        });
    }

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/postAccounts/getAllAccounts`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data.data);
            }
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    return <div className='component__settingsModal'>
        <div className='component__settingsModal__wrapper'>
            <div className='component__settingsModal__wrapper__header'>
                <p>Integracijske postavke</p>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>

            {!spinner ? <div className='component__settingsModal__wrapper__body'>
                <div className='component__settingsModal__wrapper__body__box'>
                    <h1>Bosna</h1>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Api ključ</label>
                        <input ref={BAapiRef} type='text' defaultValue={data && data[0] ? data[0].ApiKey : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Ime računa</label>
                        <input ref={BAnameRef} type='text' defaultValue={data && data[0] ? data[0].SenderName : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Poštanski broj računa</label>
                        <input ref={BAzipRef} type='text' defaultValue={data && data[0] ? data[0].SenderPostalCode : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Ulica računa</label>
                        <input ref={BAstreetRef} type='text' defaultValue={data && data[0] ? data[0].SenderStreet : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Grad računa</label>
                        <input ref={BAcityRef} type='text' defaultValue={data && data[0] ? data[0].SenderCity : ""} />
                    </div>
                </div>
                <div className='component__settingsModal__wrapper__body__box'>
                    <h1>Srbija</h1>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Api ključ</label>
                        <input ref={RSapiRef} type='text' defaultValue={data && data[1] ? data[1].ApiKey : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Ime računa</label>
                        <input ref={RSnameRef} type='text' defaultValue={data && data[1] ? data[1].SenderName : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Mjesto ID</label>
                        <input ref={RSzipRef} type='text' defaultValue={data && data[1] ? data[1].SenderPostalCode : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Ulica ID</label>
                        <input ref={RSstreetRef} type='text' defaultValue={data && data[1] ? data[1].SenderStreet : ""} />
                    </div>
                    <div className='component__settingsModal__wrapper__body__box__field'>
                        <label>Opština ID</label>
                        <input ref={RScityRef} type='text' defaultValue={data && data[1] ? data[1].SenderCity : ""} />
                    </div>
                </div>
            </div> : null}
            {spinner && <Spinner color='#ffffff' style={{ width: '38px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            <div className='component__settingsModal__wrapper__actions'>
                <div onClick={() => saveAccountBA()} className='component__settingsModal__wrapper__actions__button'>
                    {!btnSpinner && <p>Spremi (Bosna)</p>}
                    {btnSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                </div>
                <div onClick={() => saveAccountRS()} className='component__settingsModal__wrapper__actions__button'>
                    {!btnSpinner && <p>Spremi (Srbija)</p>}
                    {btnSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                </div>
            </div>
        </div>
    </div>
}