import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment';

export default function Agents(props) {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [bonusesSpinner, setBonusesSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [period, setPeriod] = React.useState('today');
    const [stats, setStats] = React.useState([]);
    const [timeLeft, setTimeLeft] = React.useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/agents/getStats`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setData(res.data);
                setStats(res.data.data?.today);
            }
            else {
                setMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setMessage('Došlo je do greške...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const restartBonuses = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/restartBonuses`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getData();
            }
            else {
                setMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setMessage('Došlo je do greške...');
        }).finally(() => {
            setBonusesSpinner(false);
        });
    }

    React.useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59);
            const difference = endOfYear - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / (1000 * 60)) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setTimeLeft({
                    days,
                    hours,
                    minutes,
                    seconds
                });
            } else {
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                });
            }
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        switch (period) {
            case 'today':
                setStats(data.data?.today);
                break;
            case 'week':
                setStats(data.data?.week);
                break;
            case 'month':
                setStats(data.data?.month);
                break;
            case 'year':
                setStats(data.data?.year);
                break;
        }
    }, [period]);

    return (
        <div className='route__agents'>
            {!spinner && <div className='route__agents__body'>
                <div className='route__agents__body__reward'>
                    <div className='route__agents__body__reward__left'>
                        <img src='images/gift-card.png' alt='' />
                        <p>Osvoji zimovanje za dvoje!</p>
                    </div>
                    <div className='route__agents__body__reward__right'>
                        <p>{timeLeft.days} <small>dana</small></p>
                        <p>{timeLeft.hours} <small>sati</small></p>
                        <p>{timeLeft.minutes} <small>minuta</small></p>
                        <p>{timeLeft.seconds} <small>sekundi</small></p>
                    </div>
                </div>
                <div className='route__agents__body__userInfo'>
                    <div className='route__agents__body__userInfo__left'>
                        <img src='images/agentProfile.webp' alt='' />
                        <span className='route__agents__body__userInfo__left__span'>
                            {!props.userData?.Flags.isAdmin ? <strong>{(stats)?.filter(agn => agn.agentId === props.userData?.ID)[0].name}</strong> : <strong>{props.userData.FirstName} {props.userData.LastName}</strong>}
                            <p>Takmičite se na osnovu koeficijenta, koeficijent se računa tako što se vaš <br /> promet sabere sa prosječnom prodajom i sa ostvarenim bonusom te nakon toga podijeli sa 3</p>
                        </span>
                    </div>
                    {!props.userData?.Flags.isAdmin  && <div className='route__agents__body__userInfo__right'>
                        <strong>Tvoj bonus:</strong>
                        {!props.userData?.Flags.isAdmin ? <p>{(stats)?.filter(agn => agn.agentId === props.userData?.ID)[0].bonus} KM</p> : <p>0 KM</p>}
                    </div>}
                    {props.userData?.Flags.isAdmin && <div 
                    onClick={() => restartBonuses()} 
                    style={{cursor: 'pointer', width: '150px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} 
                    className='route__agents__body__userInfo__right'
                    >
                        {!bonusesSpinner && <p>Bonus restart</p>}
                        {bonusesSpinner && <Spinner style={{ width: '18px', height: '18px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='rgb(203, 30, 196)' />}
                    </div>}
                </div>
                <div className='route__agents__body__filters'>
                    <div className='route__agents__body__filters__container'>
                        <span onClick={() => setPeriod('today')} style={{ background: period == 'today' ? '#232d4e' : 'transparent' }}><p>24h</p></span>
                        <span onClick={() => setPeriod('week')} style={{ background: period == 'week' ? '#232d4e' : 'transparent' }}><p>7D</p></span>
                        <span onClick={() => setPeriod('month')} style={{ background: period == 'month' ? '#232d4e' : 'transparent' }}><p>30D</p></span>
                        <span onClick={() => setPeriod('year')} style={{ background: period == 'year' ? '#232d4e' : 'transparent' }}><p>Godina</p></span>
                    </div>
                </div>
                <div className='route__agents__body__topThree'>
                    <div className='route__agents__body__topThree__box'>
                        <div className='route__agents__body__topThree__box__top'>
                            <span>
                                <img src='images/agentProfile.webp' alt='' />
                                <p>{stats[0].name}</p>
                            </span>
                            <img src='images/gold-cup.png' alt='' />
                        </div>
                        <div className='route__agents__body__topThree__box__bottom'>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                <p>Approve rate</p>
                                <strong>{stats[0][period]?.approveRate}%</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[0][period].approveRate + '%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                {window.innerWidth > 1290 ? <p>Prosječna prodaja</p> : <p>P. prodaja</p>}
                                <strong>{stats[0][period]?.averageSales} EUR</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[0][period].averageSales < 30 && stats[0][period].averageSales > 0 ? '20%' : stats[0][period].averageSales > 30 && stats[0][period].averageSales < 50 ? '40%' : stats[0][period].averageSales > 50 && stats[0][period].averageSales < 80 ? '70%' : stats[0][period].averageSales > 80 ? '100%' : '0%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                <p>Koeficijent</p>
                                <strong>{stats[0][period]?.coefficient}</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[0][period].coefficient <= 100 ? stats[0][period].coefficient + '%' : '100%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                        </div>
                    </div>
                    <div className='route__agents__body__topThree__box'>
                        <div className='route__agents__body__topThree__box__top'>
                            <span>
                                <img src='images/agentProfile.webp' alt='' />
                                <p>{stats[1].name}</p>
                            </span>
                            <img src='images/silver-cup.png' alt='' />
                        </div>
                        <div className='route__agents__body__topThree__box__bottom'>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                <p>Approve rate</p>
                                <strong>{stats[1][period]?.approveRate}%</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[1][period].approveRate + '%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                {window.innerWidth > 1290 ? <p>Prosječna prodaja</p> : <p>P. prodaja</p>}
                                <strong>{stats[1][period]?.averageSales} EUR</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[1][period].averageSales && stats[1][period].averageSales > 0 < 30 ? '20%' : stats[1][period].averageSales > 30 && stats[1][period].averageSales < 50 ? '40%' : stats[1][period].averageSales > 50 && stats[1][period].averageSales < 80 ? '70%' : stats[1][period].averageSales > 80 ? '100%' : '0%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                <p>Koeficijent</p>
                                <strong>{stats[1][period]?.coefficient}</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[1][period].coefficient <= 100 ? stats[1][period].coefficient + '%' : '100%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                        </div>
                    </div>
                    <div className='route__agents__body__topThree__box'>
                        <div className='route__agents__body__topThree__box__top'>
                            <span>
                                <img src='images/agentProfile.webp' alt='' />
                                <p>{stats[2].name}</p>
                            </span>
                            <img src='images/bronze-cup.png' alt='' />
                        </div>
                        <div className='route__agents__body__topThree__box__bottom'>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                <p>Approve rate</p>
                                <strong>{stats[2][period]?.approveRate}%</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[2][period].approveRate + '%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                {window.innerWidth > 1290 ? <p>Prosječna prodaja</p> : <p>P. prodaja</p>}
                                <strong>{stats[2][period]?.averageSales} EUR</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[2][period].averageSales < 30 && stats[2][period].averageSales > 0 ? '20%' : stats[2][period].averageSales > 30 && stats[2][period].averageSales < 50 ? '40%' : stats[2][period].averageSales > 50 && stats[2][period].averageSales < 80 ? '70%' : stats[2][period].averageSales > 80 ? '100%' : '0%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                            <div className='route__agents__body__topThree__box__bottom__item'>
                                <p>Koeficijent</p>
                                <strong>{stats[2][period]?.coefficient}</strong>
                                <div className='route__agents__body__topThree__box__bottom__item__progress'><div style={{ width: stats[2][period].coefficient <= 100 ? stats[2][period].coefficient + '%' : '100%' }} className='route__agents__body__topThree__box__bottom__item__progress__fill'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='route__agents__body__table'>
                    <div className='route__agents__body__table__header'>
                        <p>Poz.</p>
                        <p>Ime i prezime</p>
                        <p>Obradio leadova</p>
                        <p>Approve rate</p>
                        {window.innerWidth > 1290 ? <p>Prosječna prodaja</p> : <p>P. prodaja</p>}
                        <p>Promet</p>
                        <p>Bonus</p>
                        <p>Koeficijent</p>
                    </div>
                    <div className='route__agents__body__table__content'>
                        {
                            stats?.map((agent, index) => {
                                return <div className='route__agents__body__table__content__row'>
                                    <p>{index + 1}</p>
                                    <p>{agent.name}</p>
                                    <p>{agent[period].processedLeads}</p>
                                    <p>{agent[period].approveRate} %</p>
                                    <p>{agent[period].averageSales} EUR</p>
                                    <p>{agent[period].totalRevenue} EUR</p>
                                    <p>{agent.bonus} KM</p>
                                    <p>{agent[period].coefficient}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>}
            {spinner && <Spinner style={{ width: '32px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
            {message && <p style={{ color: '#9b0b0b' }}>{message}</p>}
        </div>
    )
}
