import React, { useEffect, useState, useRef } from 'react';
import * as backendModule from "../../modules/backendModule";
import Spinner from '../Spinner';
import "./index.scss";

const LogViewer = (props) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const logEndRef = useRef(null);

    useEffect(() => {
        props.mainFunction(setLoading, setError);
    }, []);

    useEffect(() => {
        const socket = new WebSocket(backendModule.webSocketURL);

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'oneexpress_statuses_logs') {
                setLogs((prevLogs) => [...prevLogs, data.message]);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket greška:', error);
        };

        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        if (logEndRef.current) {
            logEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [logs]);

    return (
        <div className='component__logViewer'>
            <div className='component__logViewer__wrapper'>
                <div className='component__logViewer__wrapper__header'>
                    <h3>RocketAd CRM proces log-s</h3>
                </div>
                {!error && <div className='component__logViewer__wrapper__screen'>
                    {logs.length > 0 ? (
                        <>
                            {logs.map((log, index) => (
                                <div
                                    className={String(log).includes('|GREEN|') ? 'component__logViewer__wrapper__screen__oneLog green' : String(log).includes('|RED|') ? 'component__logViewer__wrapper__screen__oneLog red' : String(log).includes('|PURPLE|') ? 'component__logViewer__wrapper__screen__oneLog purple' : String(log).includes('|YELLOW|') ? 'component__logViewer__wrapper__screen__oneLog yellow' : 'component__logViewer__wrapper__screen__oneLog'}
                                    key={index}
                                >
                                    {String(log).includes('|GREEN|') ? String(log).replace('|GREEN|', '') : String(log).includes('|RED|') ? String(log).replace('|RED|', '') : String(log).includes('|PURPLE|') ? String(log).replace('|PURPLE|', '') : String(log).includes('|YELLOW|') ? String(log).replace('|YELLOW|', '') : null}
                                </div>
                            ))}
                            <div ref={logEndRef}></div>
                        </>
                    ) : (
                        <p style={{ color: 'white' }}>Nema logova za prikaz.</p>
                    )}
                </div>}
                {error && <p style={{color: 'white'}}>{error}</p>}
                <div className='component__logViewer__wrapper__actions'>
                    {loading ? ( 
                        <div className='component__logViewer__wrapper__actions__spinner'>
                            <Spinner color='#ffffff' style={{ width: '32px', display: 'block' }} />
                        </div>
                    ) : (
                        <div onClick={props.onClose} className='component__logViewer__wrapper__actions__close'>
                            <p>Zatvori</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LogViewer;