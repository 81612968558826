import React from 'react';
import "./index.scss";
import Spinner from '../Spinner';
import axios, { all } from "axios";
import * as backendModule from "../../modules/backendModule";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampActions";
import animateModule from "../../modules/animateModule";
import { useNavigate } from "react-router-dom";

export default function Sidebar(props) {
  const [activeTab, setActiveTab] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [appRate, setAppRate] = React.useState(0);
  const [todayAppRate, setTodayAppRate] = React.useState(0);
  const [dayData, setDayData] = React.useState([]);
  const [allLeads, setAllLeads] = React.useState([]);
  const [approvedLeads, setApprovedLeads] = React.useState([]);
  const [mobile, setMobile] = React.useState(false);

  const mainNavigate = useNavigate();
  const curDispatch = useDispatch();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__contentWrapper__body__routes"));
  };

  const logout = () => {
    axios({
      method: "GET",
      url: `${backendModule.backendURL}/auth/logout`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      curDispatch(timestampActions.updateTimestamp());
      animateNavigate('/');
      setTimeout(() => { window.location.reload(); }, 500);
    }).catch(() => {
    }).finally(() => {
    });
  }

  const getTodayProcessedLeads = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getTodayProcessedLeads`,
      data: {
        filters: [],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setDayData(res.data);
        setSpinner(false);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    });
  }

  const getAllLeads = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeadsExcNew`,
      data: {
        filters: [],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setAllLeads(res.data);
        setSpinner(false);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    });
  }

  const getApprovedLeads = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllApprovedLeads`,
      data: {
        filters: [],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setApprovedLeads(res.data);
        setSpinner(false);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    });
  }

  React.useEffect(() => {
    getTodayProcessedLeads();
    getAllLeads();
    getApprovedLeads();
  }, []);

  React.useEffect(() => {
    setAppRate((Number(approvedLeads?.data?.length) / Number(allLeads?.data?.length) * 100).toFixed(0));
  }, [allLeads, approvedLeads]);

  React.useEffect(() => {
    if (dayData?.data?.length > 0) {
      setTodayAppRate((Number(dayData?.data?.filter(e => e.Status === 1 || e.Status === 2 || e.Status === 3 || e.Status === 8).length) / Number(dayData?.data?.filter(e => e.Status != 0).length) * 100).toFixed(0))
    }
  }, [dayData]);

  return (
    <div style={{minHeight: '100%'}}>
      {window.innerWidth < 1195 && <div className='component__sidebar__logoMobile'>
        <img onClick={() => setMobile(m => !m)} src='images/Menu.svg' alt='' />
        <div className='component__sidebar__logoMobile__center'>
          <img src='images/logo.png' alt='' />
          <h1>RocketAd CRM</h1>
        </div>
      </div>}

      <div className={`${mobile ? 'component__sidebar mobile' : 'component__sidebar'}`}>
        <div className='component__sidebar__logo'>
          <img className='component__sidebar__logo__img' src='images/logo.png' alt='' />
          <h1>RocketAd CRM</h1>
          {mobile && <img onClick={() => setMobile(m => !m)} className='component__sidebar__logo__close' src='images/closemenu.svg' alt='' />}
        </div>

        <div className='component__sidebar__nav'>
          {!props.userData?.Flags?.isPost && <p className={`${activeTab === 'kanban' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('kanban'); animateNavigate('/kanban') }} ><img src='images/kanban.svg' alt='' />Kanban</p>}
          {props.userData?.Flags?.isAdmin && <p className={`${activeTab === 'products' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('products'); animateNavigate('/products') }}><img src='images/products.svg' alt='' />Proizvodi</p>}
          {props.userData?.Flags?.isAdmin || props.userData?.Flags?.isPartner ? <p className={`${activeTab === 'offers' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('offers'); animateNavigate('/offers') }}><img src='images/offers.svg' alt='' />Offers</p> : null}
          {props.userData?.Flags?.isAdmin || props.userData?.Flags?.isCCmanager ? <p className={`${activeTab === 'atwork' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('atwork'); animateNavigate('/atwork') }}><img src='images/atwork.svg' alt='' />Smjena</p> : null}
          {props.userData?.Flags?.isAdmin && <p className={`${activeTab === 'statistics' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('statistics'); animateNavigate('/statistics') }}><img src='images/stats.svg' alt='' />Statistika</p>}
          {!props.userData?.Flags?.isCCagent && !props.userData?.Flags?.isCCmanager && !props.userData?.Flags?.isPost && !props.userData?.Flags?.isPartner && <p className={`${activeTab === 'finances' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('finances'); animateNavigate('/finances') }}><img src='images/finances.svg' alt='' />Finansije</p>}
          {!props.userData?.Flags?.isCCagent && !props.userData?.Flags?.isCCmanager && !props.userData?.Flags?.isPost && !props.userData?.Flags?.isPartner && <p className={`${activeTab === 'partners' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('partners'); animateNavigate('/partners') }}><img src='images/publishers.svg' alt='' />Partneri</p>}
          {props.userData?.Flags?.isAdmin || props.userData?.Flags?.isPost ? <p className={`${activeTab === 'post' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('post'); animateNavigate('/post') }}><img src='images/post.svg' alt='' />Pošta</p> : null}
          {props.userData?.Flags?.isAdmin && <p className={`${activeTab === 'users' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('users'); animateNavigate('/users') }}><img src='images/users.svg' alt='' />Korisnici</p>}
          {!props.userData?.Flags?.isPartner && !props.userData?.Flags?.isPost && <p className={`${activeTab === 'agents' ? 'component__sidebar__nav__item active' : 'component__sidebar__nav__item'}`} onClick={(e) => { setActiveTab('agents'); animateNavigate('/agents') }}><img src='images/agents.svg' alt='' />Agenti</p>}
        </div>

        {!props.userData?.Flags?.isPartner && <div className='component__sidebar__appRates'>
          <div className='component__sidebar__appRates__total'>
            {!spinner && <div className='component__sidebar__appRates__total__head'>
              <strong>Vijek</strong>
              {!spinner && <small>{Number(approvedLeads?.data?.length)} / {Number(allLeads?.data?.length)}</small>}
            </div>}
            {!spinner && <div className='component__sidebar__appRates__total__proccess'>
              <div className='component__sidebar__appRates__total__proccess__line'><div className='component__sidebar__appRates__total__proccess__line__fill' style={{ width: appRate > 0 ? appRate + '%' : '0%' }}></div></div>
              <p>{isNaN(appRate) ? '0' : appRate}%</p>
            </div>}
            {spinner && <Spinner color='#ffffff' style={{ width: '20px' }} />}
          </div>

          <div className='component__sidebar__appRates__total'>
            {!spinner && <div className='component__sidebar__appRates__total__head'>
              <strong>Danas</strong>
              {!spinner && <small>{Number(dayData?.data?.filter(e => e.Status === 1 || e.Status === 2 || e.Status === 3 || e.Status === 8).length)} / {Number(dayData?.data?.filter(e => e.Status != 0).length)}</small>}
            </div>}
            {!spinner && <div className='component__sidebar__appRates__total__proccess'>
              <div className='component__sidebar__appRates__total__proccess__line'><div className='component__sidebar__appRates__total__proccess__line__fill' style={{ width: todayAppRate + '%' }}></div></div>
              <p>{isNaN(todayAppRate) ? '0' : todayAppRate}%</p>
            </div>}
            {spinner && <Spinner color='#ffffff' style={{ width: '20px' }} />}
          </div>
        </div>}
        <strong className={`${mobile ? 'component__sidebar__logout mobileLogout' : 'component__sidebar__logout'}`} onClick={() => logout()}> <img src='images/logout.svg' alt='' /> Odjavi se</strong>
      </div>
    </div>
  )
}
